import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class EmailSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      mailChimpResponse: null
    };
  }

  handleChange = e => {
    this.setState({emailAddress: e.target.value});
  }

  handleSubmit = async e => {
    e.preventDefault();
    const mailChimpResponse = await addToMailchimp(this.state.emailAddress);
    var subscribeResultMessage = mailChimpResponse.msg;

    if (mailChimpResponse.result === 'success') {
      subscribeResultMessage = 'Thanks for joining!';
    } else if (subscribeResultMessage.indexOf('is already subscribed to list hymnsonline.org') > -1) {
      var indexOfUpdateProfileLink = subscribeResultMessage.indexOf('<a href=');
      subscribeResultMessage = "Hmm, it looks like you’re already on the list! " + subscribeResultMessage.substring(indexOfUpdateProfileLink) + ".";
    }

    this.setState({
      emailAddress: '', // Clear the input field
      justSignedUp: mailChimpResponse.result === 'success',
      mailChimpResponse,
      subscribeResultMessage
    });
  };

  render() {
    return (
      <>
        <div id="mc_embed_signup">
          <form onSubmit={this.handleSubmit}>
            <h2>Get Email Updates</h2>
            <p>
              Want to know when new content is posted? Join the email list and we’ll let you know!
            </p>
            { !this.state.justSignedUp &&
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email:</label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" value={this.state.emailAddress} onChange={this.handleChange} />
              <span className="buttonContainer"><input type="submit" value="Sign me up!" name="subscribe" id="mc-embedded-subscribe" className="button" /></span>
            </div>
            }
            { this.state.subscribeResultMessage &&
             <p id="subscribe-result" className={this.state.mailChimpResponse.result} dangerouslySetInnerHTML={{ __html: this.state.subscribeResultMessage }} />
            }
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
              <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dc4a0db60d53bd963dce01f76_ad47322e29" tabIndex="-1" /></div>
          </form>
        </div>
      </>
    );
  }
}
